import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import striptags from "striptags"

import { brandRules } from "utils/brand"
import { insightsPath } from "utils/insights"
import Layout from "components/layout"
import SEO from "components/seo"
import Post from "components/post"
import Locked from "images/icons/locked.png"
import ButtonLE from "components/button-le"
import isBrowser from "utils/is-browser"

export const Loading = styled.div`
  ${tw`py-32 font-display font-bold text-white bg-black h-screen flex flex-col items-center justify-center text-center`}

  img {
    @media (max-width: 720px) {
      height: 50px;
      width: 50px;
    }
  }

  h1 {
    font-size: 24px;

    @media (min-width: 720px) {
      font-size: 36px;
    }
  }
`

export async function getPremiumPost(token) {
  const url = `${process.env.GATSBY_DEMAND360_SIGNUP_API}/premium_insights?token=${token}`
  try {
    const [response] = await Promise.all([
      fetch(url),
      new Promise((r) => setTimeout(r, 3000)),
    ])
    const body = await response.json()
    if (body.insightsArticle) {
      const post = body.insightsArticle
      return post
    }
  } catch (e) {
    return null
  }
  return null
}

export const LoadingScreen = ({ loading }) => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        loading: websiteSetting(
          where: { name: "Premium Insights loading message" }
        ) {
          value
        }
        expired: websiteSetting(
          where: { name: "Premium Insights invalid link" }
        ) {
          value
        }
        sharedContent: contentBlocks(
          where: { identifier: "shared-premium-insight-cta" }
        ) {
          title
          mainContent {
            html
          }
          callToAction
          linkUrl
        }
      }
    }
  `)
  const { value: loadingMessage } = data.gcms.loading
  const { value: expiredMessage } = data.gcms.expired
  const cb = data.gcms.sharedContent[0]

  return (
    <Loading>
      {!!loading && (
        <>
          <img src={Locked} height={100} width={100} alt="" />
          <h1
            className="mt-8 m:w-2/3"
            dangerouslySetInnerHTML={{ __html: brandRules(loadingMessage) }}
          />
        </>
      )}
      {!loading && (
        <>
          <h1
            className="m:w-2/3"
            dangerouslySetInnerHTML={{ __html: brandRules(expiredMessage) }}
          />
          <ButtonLE className="text-white mt-8" to={cb.linkUrl}>
            <span
              dangerouslySetInnerHTML={{ __html: brandRules(cb.callToAction) }}
            />
          </ButtonLE>
        </>
      )}
    </Loading>
  )
}

export default () => {
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState(null)

  async function loadPost(token) {
    const post = await getPremiumPost(token)
    post.relativePath = insightsPath(post)
    setPost(post)
    setLoading(false)
    if (isBrowser) {
      window.history.replaceState({}, post.title, post.relativePath)
    }
  }

  useEffect(() => {
    if (isBrowser && window.location.hash) {
      const token = window.location.hash.substring(1)
      loadPost(token)
    }
  }, [])

  return (
    <Layout>
      {post === null && <LoadingScreen loading={loading} />}
      {post !== null && (
        <>
          <SEO
            title={post.title}
            description={striptags(post.excerpt) || ""}
            meta={[
              {
                property: "og:type",
                content: "article",
              },
              {
                property: "article:section",
                content: post.insightsCategories?.length
                  ? post.insightsCategories[0].name
                  : null,
              },
            ]}
            path={post.path}
            sharePreviewUrls={{
                social: post.featuredImage?.url,
                twitter: post.featuredImage?.url
            }}
          />
          <Post post={post} shared={true} />
        </>
      )}
    </Layout>
  )
}
